<template>
    <layout>
        <page-header :title="title" :items="items" />

        <div class="card-box">
            <awesome-table
                id="es-factory-data"
                :labels="labels"
                :items="orderProductColors"
                :options="{
                    addButton: false,
                    sort: true,
                    select: true,
                    filters: true,
                    columnsSelector: true,
                    csvExport: false,
                    isCard: false,
                    clickableRows: false
                }"
                :pagination="pagination"
                :is-loading="isLoading"
                :filters="filters"
                @pagination-change="onPaginationChange"
                @selection-change="onSelectionChange"
                @filter-change="onFilterChange"
            >
                <template #selectButtons>
                    <b-dropdown
                        variant="info"
                        class="mr-1"
                        style="width: 160px;"
                        :disabled="exporting"
                    >
                        <template slot="button-content">
                            {{ exporting ? 'Downloading...' : 'Download Files' }}
                            <i v-if="!exporting" class="mdi mdi-chevron-down ml-1" />
                        </template>
                        <b-dropdown-item href="#" @click="downloadOrtFiles(false)">
                            Download
                        </b-dropdown-item>
                        <b-dropdown-item href="#" @click="downloadOrtFiles(true)">
                            Download & Change Status to:
                            ES - PRINTED & READY FOR PRODUCTION
                        </b-dropdown-item>
                    </b-dropdown>

                    <b-dropdown
                        variant="info"
                        class="mr-1"
                        style="width: 160px;"
                        :disabled="exporting"
                    >
                        <template slot="button-content">
                            Change Status
                            <i class="mdi mdi-chevron-down ml-1" />
                        </template>
                        <b-dropdown-item
                            v-for="statusOption in statusOptions"
                            :key="statusOption"
                            href="#"
                            @click="changeStatus(statusOption)"
                        >
                            {{ statusOption }}
                        </b-dropdown-item>
                    </b-dropdown>
                </template>
                <template #[`items.order.order_number`]="{ item }">
                    {{ item.order.order_number || item.order.magento_order_number }}
                </template>

                <template #[`items.status`]="{ item }">
                    <b-dropdown
                        class="edit-status-dropdown"
                        toggle-class="text-decoration-none btn-sm"
                        variant="light"
                        block
                    >
                        <template slot="button-content">
                            {{ item.status }}
                        </template>
                        <b-dropdown-item
                            v-for="optionItem in filterStatusOptions(item.status)"
                            :key="optionItem"
                            href="#"
                            @click="changeStatus(optionItem, item.id)"
                        >
                            {{ optionItem }}
                        </b-dropdown-item>
                    </b-dropdown>
                </template>

                <template #[`items.productColor.product.sku`]="{ item }">
                    {{ item.productColor.product.sku || item.assigned_sku }}
                </template>

                <template #[`items.ort_file_link`]="{ item }">
                    <a
                        v-if="item.ort_file_link"
                        :href="item.ort_file_link"
                        target="_blank"
                        class="text-truncate text-secondary ort-file-link"
                        @click.stop
                    >
                        {{ item.ort_file_link }}
                    </a>

                    <div v-if="item.ort_file_link" class="btn-group ml-1">
                        <button
                            class="btn btn-sm btn-light"
                            @click="copyToClipboard(item.ort_file_link)"
                        >
                            <i class="far fa-copy" />
                        </button>
                        <a
                            :href="getDownloadLink(item.ort_file_link)"
                            target="_blank"
                            class="btn btn-sm btn-light"
                            style="margin-left: 1px"
                            @click.stop
                        >
                            <i class="fe-download" />
                        </a>
                    </div>
                </template>

                <template #[`items.ort_file_link_2`]="{ item }">
                    <a
                        v-if="item.ort_file_link_2"
                        :href="item.ort_file_link_2"
                        target="_blank"
                        class="text-truncate text-secondary ort-file-link"
                        @click.stop
                    >
                        {{ item.ort_file_link_2 }}
                    </a>

                    <div v-if="item.ort_file_link_2" class="btn-group ml-1">
                        <button
                            class="btn btn-sm btn-light"
                            @click="copyToClipboard(item.ort_file_link_2)"
                        >
                            <i class="far fa-copy" />
                        </button>
                        <a
                            :href="getDownloadLink(item.ort_file_link_2)"
                            target="_blank"
                            class="btn btn-sm btn-light"
                            style="margin-left: 1px"
                            @click.stop
                        >
                            <i class="fe-download" />
                        </a>
                    </div>
                </template>

                <template #[`items.instruction`]="{ item }">
                    <div v-if="item.instruction" class="btn-group ml-1">
                        <button
                            class="btn btn-sm btn-blue"
                        >
                            <i class="far fa-eye" />
                        </button>
                        <button
                            class="btn btn-sm btn-blue"
                            style="margin-left: 1px"
                        >
                            <i class="fe-download" />
                        </button>
                    </div>
                </template>
            </awesome-table>
        </div>
    </layout>
</template>

<script>
import { mapActions } from 'vuex';
import moment from 'moment';
import appConfig from '@src/app.config';

export default {
    page: {
        title: 'Factory Data',
        meta: [{ name: 'description', content: appConfig.description }]
    },

    data() {
        const {
            page: currentPage = 1,
            perPage = 10,
            sortBy = 'created_at',
            order = 'DESC',
            q = null,
            filters = '{}'
        } = this.$route.query;

        return {
            title: 'Factory Data',
            items: [
                {
                    text: 'Upstep',
                    to: '/'
                },
                {
                    text: 'Factory Data',
                    active: true
                }
            ],
            labels: [
                {
                    text: 'Order Number',
                    value: 'order.order_number'
                },
                {
                    value: 'status',
                    type: 'enum',
                    enumOptions: [
                        'ES - DESIGNED & READY FOR PRINTING',
                        'ES - PRINTED & READY FOR PRODUCTION'
                    ]
                },
                {
                    text: 'SKU',
                    value: 'productColor.product.sku',
                    sortable: false,
                    filterable: false
                },
                'quantity',
                {
                    text: 'Design Date',
                    value: 'instruction.created_at',
                    filter: 'formatDateOnly',
                    type: 'date',
                    initialFilterType: 'between'
                },
                {
                    text: 'File 1',
                    value: 'ort_file_link',
                    width: '270px'
                },
                {
                    text: 'File 2',
                    value: 'ort_file_link_2',
                    width: '270px'
                },
                {
                    value: 'instruction',
                    sortable: false,
                    filterable: false,
                    width: '90px'
                }
            ],
            orderProductColors: [],
            pagination: {
                currentPage: parseInt(currentPage),
                perPage: parseInt(perPage),
                total: 0,
                search: q || '',
                sortBy,
                descending: order.toLowerCase() === 'desc'
            },
            filters: JSON.parse(filters),
            selected: [],
            isLoading: false,
            exporting: false,
            statusOptions: [
                'ES - DESIGNED & READY FOR PRINTING',
                'ES - PRINTED & READY FOR PRODUCTION'
            ]
        };
    },

    async created() {
        this.fetchOrderProductColors();
    },

    methods: {
        ...mapActions({
            getOrderProductColors: 'orderProductColors/esIndex',
            bulkChangeStatus: 'orderProductColors/bulkChangeStatus',
            bulkDownloadFiles: 'orderProductColors/bulkDownloadFiles'
        }),

        async fetchOrderProductColors() {
            this.isLoading = true;

            try {
                const options = {
                    ...this.pagination,
                    filters: this.filters
                };

                const { rows, count } = await this.getOrderProductColors(
                    options
                );

                if (options.currentPage !== this.pagination.currentPage) {
                    return;
                }

                this.orderProductColors = rows;
                this.pagination.total = count;
                this.pagination.pages = Math.ceil(
                    this.pagination.total / this.pagination.perPage
                );
            } catch (err) {
                console.error(err);

                this.$toasterError();
            }

            this.isLoading = false;
        },

        onPaginationChange(pagination) {
            this.pagination = {
                ...pagination
            };

            this.fetchOrderProductColors();
        },

        onSelectionChange(items) {
            this.selected = [...items];
        },

        onFilterChange(filters) {
            this.filters = { ...filters };
            this.pagination.currentPage = 1;

            this.fetchOrderProductColors();
        },

        async copyToClipboard(text) {
            await navigator.clipboard.writeText(text);

            this.$toaster('Copied to clipboard!');
        },

        getDownloadLink(link) {
            return link.replace(/dl=0$/, 'dl=1');
        },

        filterStatusOptions(status) {
            return this.statusOptions.filter(option => option !== status);
        },

        async changeStatus(status, id) {
            const ids = id ? [id] : this.selected;

            try {
                await this.bulkChangeStatus({
                    ids,
                    status
                });

                this.fetchOrderProductColors();

                this.$toaster('Status has been updated!');
            } catch (err) {
                console.error(err);

                this.$toasterError();
            }
        },

        async downloadOrtFiles(changeStatus) {
            this.exporting = true;

            try {
                const download = await this.bulkDownloadFiles(this.selected);

                const fileURL = window.URL.createObjectURL(
                    new Blob([download], { type: 'application/zip' })
                );

                const now = moment().format('YYYY-MM-DD_hh:mm:ss');

                this.$downloadFile(fileURL, `scans_${now}.zip`);

                if (changeStatus) {
                    this.changeStatus('ES - PRINTED & READY FOR PRODUCTION');
                }
            } catch (error) {
                console.error(error);

                this.$toasterError();
            }

            this.exporting = false;
        }
    }
};
</script>

<style lang="scss">
.vs__dropdown-menu {
    min-width: 290px;
}

.editable-status {
    cursor: pointer;
    border-radius: 6px;

    &:hover {
        background-color: aliceblue;
    }
}



.edit-status-dropdown button {
    white-space: normal;
}

.ort-file-link {
    display: inline-block;
    vertical-align: middle;
    max-width: 150px;
}
</style>
