var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('layout',[_c('page-header',{attrs:{"title":_vm.title,"items":_vm.items}}),_c('div',{staticClass:"card-box"},[_c('awesome-table',{attrs:{"id":"es-factory-data","labels":_vm.labels,"items":_vm.orderProductColors,"options":{
                addButton: false,
                sort: true,
                select: true,
                filters: true,
                columnsSelector: true,
                csvExport: false,
                isCard: false,
                clickableRows: false
            },"pagination":_vm.pagination,"is-loading":_vm.isLoading,"filters":_vm.filters},on:{"pagination-change":_vm.onPaginationChange,"selection-change":_vm.onSelectionChange,"filter-change":_vm.onFilterChange},scopedSlots:_vm._u([{key:"selectButtons",fn:function(){return [_c('b-dropdown',{staticClass:"mr-1",staticStyle:{"width":"160px"},attrs:{"variant":"info","disabled":_vm.exporting}},[_c('template',{slot:"button-content"},[_vm._v("\n                        "+_vm._s(_vm.exporting ? 'Downloading...' : 'Download Files')+"\n                        "),(!_vm.exporting)?_c('i',{staticClass:"mdi mdi-chevron-down ml-1"}):_vm._e()]),_c('b-dropdown-item',{attrs:{"href":"#"},on:{"click":function($event){return _vm.downloadOrtFiles(false)}}},[_vm._v("\n                        Download\n                    ")]),_c('b-dropdown-item',{attrs:{"href":"#"},on:{"click":function($event){return _vm.downloadOrtFiles(true)}}},[_vm._v("\n                        Download & Change Status to:\n                        ES - PRINTED & READY FOR PRODUCTION\n                    ")])],2),_c('b-dropdown',{staticClass:"mr-1",staticStyle:{"width":"160px"},attrs:{"variant":"info","disabled":_vm.exporting}},[_c('template',{slot:"button-content"},[_vm._v("\n                        Change Status\n                        "),_c('i',{staticClass:"mdi mdi-chevron-down ml-1"})]),_vm._l((_vm.statusOptions),function(statusOption){return _c('b-dropdown-item',{key:statusOption,attrs:{"href":"#"},on:{"click":function($event){return _vm.changeStatus(statusOption)}}},[_vm._v("\n                        "+_vm._s(statusOption)+"\n                    ")])})],2)]},proxy:true},{key:"items.order.order_number",fn:function(ref){
            var item = ref.item;
return [_vm._v("\n                "+_vm._s(item.order.order_number || item.order.magento_order_number)+"\n            ")]}},{key:"items.status",fn:function(ref){
            var item = ref.item;
return [_c('b-dropdown',{staticClass:"edit-status-dropdown",attrs:{"toggle-class":"text-decoration-none btn-sm","variant":"light","block":""}},[_c('template',{slot:"button-content"},[_vm._v("\n                        "+_vm._s(item.status)+"\n                    ")]),_vm._l((_vm.filterStatusOptions(item.status)),function(optionItem){return _c('b-dropdown-item',{key:optionItem,attrs:{"href":"#"},on:{"click":function($event){return _vm.changeStatus(optionItem, item.id)}}},[_vm._v("\n                        "+_vm._s(optionItem)+"\n                    ")])})],2)]}},{key:"items.productColor.product.sku",fn:function(ref){
            var item = ref.item;
return [_vm._v("\n                "+_vm._s(item.productColor.product.sku || item.assigned_sku)+"\n            ")]}},{key:"items.ort_file_link",fn:function(ref){
            var item = ref.item;
return [(item.ort_file_link)?_c('a',{staticClass:"text-truncate text-secondary ort-file-link",attrs:{"href":item.ort_file_link,"target":"_blank"},on:{"click":function($event){$event.stopPropagation();}}},[_vm._v("\n                    "+_vm._s(item.ort_file_link)+"\n                ")]):_vm._e(),(item.ort_file_link)?_c('div',{staticClass:"btn-group ml-1"},[_c('button',{staticClass:"btn btn-sm btn-light",on:{"click":function($event){return _vm.copyToClipboard(item.ort_file_link)}}},[_c('i',{staticClass:"far fa-copy"})]),_c('a',{staticClass:"btn btn-sm btn-light",staticStyle:{"margin-left":"1px"},attrs:{"href":_vm.getDownloadLink(item.ort_file_link),"target":"_blank"},on:{"click":function($event){$event.stopPropagation();}}},[_c('i',{staticClass:"fe-download"})])]):_vm._e()]}},{key:"items.ort_file_link_2",fn:function(ref){
            var item = ref.item;
return [(item.ort_file_link_2)?_c('a',{staticClass:"text-truncate text-secondary ort-file-link",attrs:{"href":item.ort_file_link_2,"target":"_blank"},on:{"click":function($event){$event.stopPropagation();}}},[_vm._v("\n                    "+_vm._s(item.ort_file_link_2)+"\n                ")]):_vm._e(),(item.ort_file_link_2)?_c('div',{staticClass:"btn-group ml-1"},[_c('button',{staticClass:"btn btn-sm btn-light",on:{"click":function($event){return _vm.copyToClipboard(item.ort_file_link_2)}}},[_c('i',{staticClass:"far fa-copy"})]),_c('a',{staticClass:"btn btn-sm btn-light",staticStyle:{"margin-left":"1px"},attrs:{"href":_vm.getDownloadLink(item.ort_file_link_2),"target":"_blank"},on:{"click":function($event){$event.stopPropagation();}}},[_c('i',{staticClass:"fe-download"})])]):_vm._e()]}},{key:"items.instruction",fn:function(ref){
            var item = ref.item;
return [(item.instruction)?_c('div',{staticClass:"btn-group ml-1"},[_c('button',{staticClass:"btn btn-sm btn-blue"},[_c('i',{staticClass:"far fa-eye"})]),_c('button',{staticClass:"btn btn-sm btn-blue",staticStyle:{"margin-left":"1px"}},[_c('i',{staticClass:"fe-download"})])]):_vm._e()]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }